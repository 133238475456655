import axios from "axios";
import { jwtClientToken } from "../utils/constants";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    responseType: 'json',
    timeout: 60 * 1000,
    headers: {
        'Authorization': `Bearer ${jwtClientToken}`,
        'Content-Type': 'application/json',
    }
})

axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const status = error?.response?.status
    if (status === 401 && error?.response?.headers?.expires === "true") {
        window.location.href = '/login';
    }
    return Promise.reject(error)
})

export const axiosPOST = async (url, data) => {
    return await axiosInstance.post(url, data);
}

export const axiosGET = async (url) => {
    return await axiosInstance.get(url);
}

export default axiosInstance