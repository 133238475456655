import React, { useEffect, useState } from "react"
import logo from '../../assets/img/logo.png'
import { fetchAuthenticate, fetchUserValidation } from "../../services/UserService"
import { Form } from "react-bootstrap"
import { Box, Container, Grid, TextField, Button, Alert, CircularProgress } from "@mui/material"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import useNotify from "../../hooks/useNotify"
import { InputAdornment } from "@mui/material"
import { PasswordTwoTone, Phone } from "@mui/icons-material"
import PageLoading from "../../components/PageLoadng"

export const Login = ({ setToken }) => {
    const [credentials, setCredentials] = useState({ username: '', password: '' })
    const [submit, setSubmit] = useState(false)
    const [searchPhone, setSearchPhone] = useState(false)
    const [phoneExistVal, setPhoneExistVal] = useState(undefined)
    const [respMsg, setRespMsg] = useState(undefined)
    const { successNotify } = useNotify()

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmit(true)
        const authenticateResponse = await fetchAuthenticate({
            'username': credentials.username,
            'password': credentials.password
        });


        if (authenticateResponse?.is_error) {
            setRespMsg(authenticateResponse.message);
        } else if (authenticateResponse?.access_token !== undefined) {
            setRespMsg(undefined);
            setToken(authenticateResponse.access_token);
            successNotify("Logged in successfully...loading")
            window.location.href = '/dashboard'
        }
        setSubmit(false)
    }


    useEffect(() => {
        if (credentials.username !== undefined && credentials.username !== '' && credentials.username.length >= 11) {
            setSearchPhone(true)
            fetchUserValidation(credentials.username).then(resp => {
                setPhoneExistVal(!resp?.is_error)
            }).finally(() => {
                setSearchPhone(false)
            });
        }

    }, [credentials.username])

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <PageLoading loading={submit} />
                <Grid>
                    <img
                        src={logo}
                        style={{ height: '130px' }}
                        alt={process.env?.COMPANY_NAME}
                    />
                </Grid>
                {respMsg ? <Alert severity="error">{respMsg}</Alert> : <></>}
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Phone Number"
                        autoFocus
                        id="text"
                        placeholder="08123456789"
                        InputProps={{
                            startAdornment: (
                                searchPhone ?
                                    <Box sx={{ mr: 1, display: 'flex', alignItems: 'center', position: 'relative' }}>
                                        <CircularProgress />
                                    </Box> :
                                    <InputAdornment position="start">
                                        <Phone />
                                    </InputAdornment>
                            ),
                        }}
                        name="phone"
                        type="phone"
                        variant="outlined"
                        onChange={e => setCredentials(prevState => {
                            return { ...prevState, username: e.target.value }
                        })}
                        required
                    />
                    <Form.Label className="text-xs text-danger"><small>{phoneExistVal === false && credentials.username.length >= 11 ? 'Phone doesn\'t exist' : ''}</small></Form.Label>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PasswordTwoTone />
                                </InputAdornment>
                            )
                        }}
                        variant="outlined"
                        autoComplete="current-password"
                        onChange={e => setCredentials(prevState => {
                            return { ...prevState, password: e.target.value }
                        })}
                    />
                    <Button
                        type="submit"
                        startIcon={<LockOutlinedIcon />}
                        variant="contained"
                        fullWidth
                        size="larger"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}