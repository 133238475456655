import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useJwtAuthToken from './hooks/useJwtAuthToken';
import { Suspense, lazy } from 'react';

import './scss/style.scss'


import { Login } from './pages/auth/Login';
import { Logout } from './pages/auth/Logout'
import { Toaster } from 'react-hot-toast';


//defer component until if was loaded
const DefaultAppLayout = lazy(() => import('./layout/DefaultAppLayout'))


function App() {

    const loadingComponent = (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
    )


    const { token, setToken, removeToken, decodeToken } = useJwtAuthToken();

    const jwtDecoded = decodeToken()

    if ((!token || !jwtDecoded?.exp) || Date.now() < Date.apply(jwtDecoded?.exp)) {

        // return (
        //     <Routes>
        //         <Route path='/' element={<Navigate to="login" replace />} />
        //     </Routes>
        // )
        return <Login setToken={setToken} />
    }

    return (
        <BrowserRouter>
            <div><Toaster /></div>
            <Suspense fallback={loadingComponent}>
                <Routes>
                    <Route path="/login" element={<Login setToken={setToken} />}></Route>
                    <Route path="/logout" name="logout" element={<Logout setToken={removeToken} />}></Route>
                    <Route path='*' name="Authorised Page" element={<DefaultAppLayout />}></Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;