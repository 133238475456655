import toast from "react-hot-toast"

export default function useNotify() {

    const successNotify = (message) => {
        return toast(message,
            {
                icon: '👏',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
    }

    const failureNotify = (title, message) => {

    }

    const infoNotify = (title, message) => {

    }

    return {
        successNotify,
        failureNotify,
        infoNotify
    }
}