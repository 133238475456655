import jwt_decode from "jwt-decode"
import { useState } from "react";

export default function useJwtAuthToken() {

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    };

    const removeToken = () => {
        localStorage.removeItem('token');
    }

    const decodeToken = () => {
        if (!token) return;
        const decoded = jwt_decode(token);
        return decoded;
    }

    return {
        setToken: saveToken,
        token,
        decodeToken,
        currentSignedInUser: decodeToken()?.obj,
        removeToken
    }
}