import { axiosGET } from "../lib/axios";
import { BASE_URL } from "../utils/constants";

const fetchAuthenticate = async (credentials) => {
    return await fetch(`${BASE_URL}/auth/login`, {
        method: 'POST',
        body: JSON.stringify(credentials)
    }).then(data => data.json())
}


const fetchUserRoles = async () => {
    return await fetch(`${BASE_URL}/auth/users-roles`, {
        method: 'GET'
    }).then(data => data.json())
}


const fetchUserValidation = async (username) => {
    return await fetch(`${BASE_URL}/auth/check-username?username=${username}`, {
        method: 'GET'
    }).then(data => data.json())
}

const fetchCustomerRecords = async (callback) => {
    return await axiosGET('/user/customer-records').then(callback)
}
export { fetchAuthenticate, fetchUserRoles, fetchUserValidation, fetchCustomerRecords }