import jwt_decode from "jwt-decode"

export const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8080/v1/api'
export const jwtClientToken = JSON.parse(localStorage.getItem('token'))
export const APP_DEVELOPERS = process.env.REACT_APP_APP_DEVELOPERS
export const CURRENCY_FORMATTER = new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN', minimumFractionDigits: 0, maximumFractionDigits: 0 })

export const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken
}

export const currentUser = () => {
    const token = getToken();
    if (!token) return;
    const decoded = jwt_decode(token);
    return decoded?.obj;
}

const loggedInUser = currentUser()

export const isCasher = loggedInUser?.role?.trim() === 'CASHIER' || loggedInUser?.role?.trim() === 'ADMIN'
export const isOperator = loggedInUser?.role?.trim() === 'OPERATOR' || loggedInUser?.role?.trim() === 'ADMIN'

